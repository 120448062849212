<template>
  <div class="top barging-container">
    <div class="flex flex-row justify-between p-1 laptop:pb-2 desktop:pb-8">
      <tabs :tabs="tabsList" @updateIndex="updateIndex" :currentIndex="upIndex"></tabs>
    </div>
    <!-- <div style="padding:20px 0px; background-color: white; border-radius: 10px;"> -->
    <div class="barging-card" style="padding:0px 0px; margin-top:18px; background-color:#f2f7fa; border-radius: 10px;">
      <div  class="flex flex-row justify-end pr-2 pl-2 mb-2 pt-2">
        <div class="flex gap-x-4 text-right" style="width: 400px">
          <el-input class="Comipt" size="small" :placeholder="$t('btn.search')" v-model="searchValue"
            @keyup.enter.native="getList"
          >
            <span slot="suffix" @click="getList" style="padding-top: 9px">
              <svg-icon iconClass="search" />
            </span>
          </el-input>
          <el-select size="small" class="Pendingselect" :clearable="true"
            v-model="selectDate" @change="handleSelectChange" :placeholder="$t('btn.Today')"
          >
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="box-card barging-table" v-bind:class="{'mobile-view':isMobileScreen}">
        <div class="w-full Comtable">
          <el-table
            :data="listData"
            :header-cell-style="{
              height: '55px',
              background: '#fff',
              'border-bottom': '2px solid #D6D9E1',
            }"
            :row-style="{
              height: screenType.isMobileScreen? '30px':'64px',
            }"
            :cell-style="getCellStyle"
            @row-click="handleClick"
            table-layout="auto"
            height="100%"
          >
            <el-table-column
              align="center"
              prop="originalName"
              :label="$t('common.FileName')"
              min-width="200"
            >
            </el-table-column>
            <el-table-column
              align="center"
              :prop="upIndex == 0 ? 'createTime' : 'updateTime'"
              :label="upIndex == 0 ? 'Date Created' : 'Date Linked'"
              min-width="100"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- <div class="flex justify-end w-full">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="pageParams.pageNum"
          :limit.sync="pageParams.pageSize"
          @pagination="getList"
        ></pagination>
      </div> -->
      <div class="barging-pagination">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="pageParams.pageNum"
          :limit.sync="pageParams.pageSize"
          @pagination="getList"
        ></pagination>
      </div>
    </div>
    <pdf-view
      title="Certificate Of Quantity"
      :pdfUrl="operationData.src"
      v-model="operationData.show"
    />
  </div>
</template>

<script>
import tabs from "@/components/tabs.vue";
import { getCoqMangeListList } from "@/services";
import { getQuantityNum } from "@/services/user";
import PdfView from "@/components/common/pdf-view.vue";
import { mapState } from "vuex";
export default {
  name: "requests",
  components: { tabs, PdfView },

  data() {
    return {
      tabsList: [
        { label: "Unlink", count: 0, index: "0" },
        { label: "Linked", count: 0, index: "1" },
      ],
      upIndex: 0,
      listData: [],
      searchValue: "",
      options: [
        {
          value: "1",
          label: "Today",
        },
        {
          value: "2",
          label: "ThisWeek",
        },
        {
          value: "3",
          label: "ThisMonth",
        },
        {
          value: "4",
          label: "ThisYear",
        },
      ],
      selectDate: "",
      pageParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      operationData: {
        src: "",
        show: false,
      },
    };
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  created() {
    this.getList();
  },
  methods: {
    updateIndex(index) {
      this.upIndex = index;
      this.getList();
    },
    handleSelectChange() {
      this.getList();
    },
    getList() {
      let data = {
        ...this.pageParams,
        status: this.upIndex,
      };
      if (this.selectDate) {
        data["searchDateType"] = this.selectDate;
      }
      if (this.searchValue) {
        data["originalName"] = this.searchValue;
        this.searchValue = "";
      }
      getQuantityNum().then((res) => {
        this.tabsList = [
          { label: "Unlink", count: res.unLink, index: "0" },
          { label: "Linked", count: res.linked, index: "1" },
        ];
      });
      getCoqMangeListList(data).then((res) => {
        this.listData = res.rows;
        this.total = res.total;
      });
    },
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    },
    handleClick(row) {
      this.operationData = {
        src: process.env.VUE_APP_BASE_API + row.url,
        show: true,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.Topinp {
  position: relative;
}
.Topinput {
  display: flex;
  position: absolute;
  top: -65px;
  right: -40px;
  .Comipt {
    width: 300px;
    height: 46px;
  }
  .Pendingselect {
    width: 221px;
    height: 46px;
    margin-left: 16px;
  }
}
.Reqcol {
  display: flex;
  justify-content: center;
  height: calc(90vh - 80px - 32 * 2px - 56px);
  overflow-y: scroll;
  .ReqTable {
    width: 1000px;
  }
  .el-button--default {
    color: #fff;
    background-color: #008CDB;
  }
  .el-button--default.is-plain:hover {
    color: #fff;
    background: #008CDB;
    border-color: #008CDB;
  }
  .el-button--default:focus {
    color: #fff !important;
    background: #008CDB !important;
    border-color: #008CDB;
  }
}
::v-deep {
  .el-input__icon.el-icon-search {
    height: 32px !important;
  }
}
::v-deep .pagination-container {
  background: #f2f7fa;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
::v-deep .el-table .cell {
  word-break:normal;
}

@media (max-width: 1023px) {
  .Comtable {
    height: calc(100vh - 16rem);
  }
  .mobile-view{
    .Comtable {
      height: calc(100vh - 15.5rem);
    }
  }
}
@media (min-width: 1024px) {
  .Comtable {
    height: calc(100vh - 14rem);
  }
  .barging-card{
    margin-top: 0px !important;
  }
}
@media (min-width: 1441px) {
  .barging-card{
    height: calc(100vh - 160px);
  }
  .Comtable {
    height: calc(100vh - 15rem);
  }
}
</style>
